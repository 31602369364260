export default function () {
	const preloader = document.querySelector(".preloader");
	let removedLoader = false;

	function removeLoader() {
		if (!removedLoader && preloader) {
			setTimeout(() => {
				document.body.style.overflow = "";
				fadePreloader();
				removedLoader = true;
			}, 1500);
		}
	}

	function fadePreloader() {
		preloader.classList.add("remove");
		setTimeout(() => {
			preloader.remove();
		}, 300);
	}

	let timeout = setTimeout(() => {
		removeLoader();
	}, 1000);

	window.onload = function () {
		clearTimeout(timeout);
		removeLoader();
	};
}
