import preloader from "../utils/preloader";
import horizontalTicker from "../components/horizontal-ticker";

export default {
	init() {
		preloader();
		horizontalTicker();
	},
	finalize() {
		console.info("%ccommon.js loaded", "color:green");
	},
};
