/* FIXES + ROUTER */
import "./fixes";
import Router from "./utils/Router";

/* PAGES */
import common from "./pages/common";
import home from "./pages/home";

/* LIBRARIES */
import barba from "@barba/core/dist/barba.js";
import { gsap } from "gsap/all";

/* Populate Router instance with DOM routes */
const routes = new Router({
	common,
	home,
});

// window.addEventListener("DOMContentLoaded", () => {
// 	routes.loadEvents();
// });

const initApp = () => {
	routes.loadEvents();
};

document.addEventListener("DOMContentLoaded", () => initApp());

barba.init({
	// name: "opacity-transition",
	// timeout: 6000, // increased timeout (standart 2000) for high pages weight
	debug: true,
	transitions: [
		{
			name: "default",
			leave(data) {
				let tl = gsap.timeline();
				tl.fromTo(
					data.current.container,
					{ opacity: 1 },
					{
						opacity: 0,
						duration: 0.25,
					}
				);
				return tl;
			},
			afterLeave(data, current, next) {
				const nextHtml = data.next.html;
				let response = nextHtml.replace(
					/(<\/?)body( .+?)?>/gi,
					"$1notbody$2>",
					nextHtml
				);
				const parser = new DOMParser();
				const doc = parser.parseFromString(response, "text/html");
				document.body.className =
					doc.querySelector("notbody").className + " page-changing";
				window.scroll({ top: 0 });
			},
			enter() {},
			afterEnter() {
				var vids = document.querySelectorAll("video");
				vids.forEach((vid) => {
					var playPromise = vid.play();
					if (playPromise !== undefined) {
						playPromise.then((_) => {}).catch((error) => {});
					}
				});
			},
			after(data, current, next) {
				let done = this.async();

				gsap.fromTo(
					data.next.container,
					{ opacity: 0 },
					{
						opacity: 1,
						duration: 0.25,
						onComplete: done,
					}
				);

				initApp();
			},
		},
		{
			name: "news-to",
			to: {
				namespace: ["news"],
			},
			once(data) {
				let tl = gsap.timeline();
				tl.set(":root", { "--color-for": "#050782" });
				tl.set(":root", { "--color-bkg": "#00ff80" }, "<");
				tl.fromTo(
					data.next.container,
					{ opacity: 0 },
					{
						opacity: 1,
						duration: 0.5,
						// ease: "power4.in",
					},
					">=0.5"
				);
				return tl;
			},
			leave(data) {
				let tl = gsap.timeline();
				tl.fromTo(
					data.current.container,
					{ opacity: 1 },
					{
						opacity: 0,
						duration: 0.25,
					}
				);
				tl.to(":root", { "--color-for": "#050782", duration: 0.25 });
				tl.to(":root", { "--color-bkg": "#00ff80", duration: 0.25 }, "<");
				return tl;
			},
			afterLeave(data, current, next) {
				const nextHtml = data.next.html;
				let response = nextHtml.replace(
					/(<\/?)body( .+?)?>/gi,
					"$1notbody$2>",
					nextHtml
				);
				const parser = new DOMParser();
				const doc = parser.parseFromString(response, "text/html");
				document.body.className =
					doc.querySelector("notbody").className + " page-changing";
				window.scroll({ top: 0 });
			},
			enter() {},
			after(data, current, next) {
				let done = this.async();

				gsap.fromTo(
					data.next.container,
					{ opacity: 0 },
					{
						opacity: 1,
						duration: 0.25,
						onComplete: done,
					}
				);

				initApp();
			},
		},
		{
			name: "news-from",
			from: {
				namespace: ["news"],
			},
			leave(data) {
				let tl = gsap.timeline();
				tl.fromTo(
					data.current.container,
					{ opacity: 1 },
					{
						opacity: 0,
						duration: 0.25,
					}
				);
				tl.to(":root", { "--color-bkg": "#050782", duration: 0.25 });
				tl.to(":root", { "--color-for": "#00ff80", duration: 0.25 }, "<");
				return tl;
			},
			afterLeave(data, current, next) {
				const nextHtml = data.next.html;
				let response = nextHtml.replace(
					/(<\/?)body( .+?)?>/gi,
					"$1notbody$2>",
					nextHtml
				);
				const parser = new DOMParser();
				const doc = parser.parseFromString(response, "text/html");
				document.body.className =
					doc.querySelector("notbody").className + " page-changing";
				window.scroll({ top: 0 });
			},
			enter() {},
			after(data, current, next) {
				let done = this.async();

				gsap.fromTo(
					data.next.container,
					{ opacity: 0 },
					{
						opacity: 1,
						duration: 0.25,
						onComplete: done,
					}
				);

				initApp();
			},
		},
	],
});

// // do something before the transition starts
// barba.hooks.before(() => {
// 	document.querySelector("html").classList.add("is-transitioning");
// });
// // do something after the transition finishes
// barba.hooks.after(() => {
// 	document.querySelector("html").classList.remove("is-transitioning");
// });
